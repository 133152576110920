import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

const TeamSection = () => {
    const [teams, setTeams] = useState([]); // State to store team data

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    useEffect(() => {
        const fetchTeams = async () => {
            try {
                const response = await axios.get('https://enablersworldwide.pythonanywhere.com/api/volunteer');
                setTeams(response.data.results); // Assuming the API returns an array of team members
            } catch (error) {
                console.error("Error fetching team data:", error);
            }
        };

        fetchTeams();
    }, []);

    return (
        <section className="volunteersSection pb-120 pt-120">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="sectionTitle text-center mb-70">
                            <span className="sectionTitle__small justify-content-center">
                                <i className="fa-solid fa-heart btn__icon"></i>
                                We stand for Change
                            </span>
                            <h2 className="sectionTitle__big">Meet Our Volunteers</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {teams.map((team, index) => (
                        <div className="col-lg-3 col-md-6 mb-45" key={index}>
                            <div className="volunteerBlock text-center">
                                <figure className="volunteerBlock__figure">
                                    <img
                                        className="volunteerBlock__figure__thumb"
                                        src={`https://res.cloudinary.com/deofjoqll/${team.profile_picture}`} // Ensure team.tImg is the correct field from the API
                                        alt="Volunteer"
                                    />
                                </figure>
                                <div className="volunteerBlock__content">
                                    <h3 className="volunteerBlock__name text-uppercase text-center">
                                        <Link onClick={ClickHandler} to={`/team-single/${team.id}`}>
                                            {team.user}
                                        </Link>
                                    </h3>
                                    <div className="itSocial itSocial--volunteer">
                                        <ul>
                                            <li>
                                                <Link onClick={ClickHandler} to={`/team-single/${team.facebook}`}>
                                                    <i className="fab fa-facebook-f"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={ClickHandler} to={`/team-single/${team.twitter}`}>
                                                    <i className="fab fa-twitter"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={ClickHandler} to={`/team-single/${team.instagram}`}>
                                                    <i className="fab fa-instagram"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link onClick={ClickHandler} to={`/team-single/${team.linkedin}`}>
                                                    <i className="fab fa-linkedin-in"></i>
                                                </Link>
</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TeamSection;


// import React from 'react';
// import Teams from '../../api/team'
// import { Link } from "react-router-dom";


// const TeamSection = (props) => {
//     const ClickHandler = () => {
//         window.scrollTo(10, 0);
//     }

//     return (

//         <section className="volunteersSection pb-120 pt-120">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-12">
//                         <div className="sectionTitle text-center mb-70">
//                             <span className="sectionTitle__small justify-content-center">
//                                 <i className="fa-solid fa-heart btn__icon"></i>
//                                 We Change Your Life & World
//                             </span>
//                             <h2 className="sectionTitle__big">Meet Our Volunteers</h2>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="row">
//                     {Teams.slice(0, 8).map((team, tm) => (
//                         <div className="col-lg-3 col-md-6 mb-45" key={tm}>
//                             <div className="volunteerBlock text-center">
//                                 <figure className="volunteerBlock__figure">
//                                     <img className="volunteerBlock__figure__thumb" src={team.tImg} alt="Gainioz Volunteers" />
//                                 </figure>
//                                 <div className="volunteerBlock__content">
//                                     <h3 className="volunteerBlock__name text-uppercase text-center">
//                                         <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>{team.name}</Link>
//                                     </h3>
//                                     <div className="itSocial itSocial--volunteer">
//                                         <ul>
//                                             <li>
//                                                 <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>
//                                                     <i className="fab fa-facebook-f"></i>
//                                                 </Link>
//                                             </li>
//                                             <li>
//                                                 <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>
//                                                     <i className="fab fa-twitter"></i>
//                                                 </Link>
//                                             </li>
//                                             <li>
//                                                 <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>
//                                                     <i className="fab fa-instagram"></i>
//                                                 </Link>
//                                             </li>
//                                             <li>
//                                                 <Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>
//                                                     <i className="fab fa-linkedin-in"></i>
//                                                 </Link>
//                                             </li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}

//                 </div>
//             </div>
//         </section>
//     );
// }

// export default TeamSection;