import React, { Fragment } from "react";
import Header2 from "../../components/Header2/Header2";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";


const DonationStatic = () => {
  // Example bank details
  const bankDetails = [
    {
      country: "Nigeria",
      bankName: "Union Bank of Africa",
      accountName: "Enablers Diaspora Grassroot Association",
      accountNumber: "0166727602",
    },
    {
      country: "South Africa",
      bankName: "First National Bank",
      accountName: "Enablers Worldwide",
      accountNumber: "63116462572",
      branchCode: "25 53 55",
    },
    {
      country: "United Kingdom",
      bankName: "Zempler Bank",
      accountName: "Enablers Diaspora Grassroot Association",
      accountNumber: "03271036",
      sortCode: "08 71 99",
    },
    {
      country: "Nigeria",
      bankName: "Access Bank",
      accountName: "Enablers Worldwide",
      accountNumber: "789123456",
    },
  ];

  return (
    <Fragment>
       <Header2 hclass={'header--styleFour'} />
      <main>
        <PageTitle pageTitle={'Donate'} pagesub={'Donation'} pageTop={'Our'}/>
        <section className="bg-gray-100 py-10">
          <div className="container mx-auto px-4">
            <h1 className="text-center text-3xl font-bold mb-8">
              Donation Bank Details
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {bankDetails.map((bank, index) => (
                <div
                  key={index}
                  className="card bg-white shadow-lg rounded-lg p-6 border border-gray-200"
                >
                  <h2 className="text-xl font-bold mb-2">{bank.country}</h2>
                  <p className="text-gray-600">
                    <strong>Bank Name:</strong> {bank.bankName}
                  </p>
                  <p className="text-gray-600">
                    <strong>Account Name:</strong> {bank.accountName}
                  </p>
                  <p className="text-gray-600">
                    <strong>Account Number:</strong> {bank.accountNumber}
                  </p>
                  { bank.sortCode ?
                  <p className="text-gray-600">
                  <strong>Sort Code:</strong> 
                  {bank.sortCode}
                   </p>
                  :
                  <p className="text-gray-600">
                    <strong>branch Code:</strong> 
                    {bank.branchCode}
                  </p>
                }
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default DonationStatic;
