import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';


const AboutTab = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    return (
        <div className="about position-relative w-full overflow-x-hidden">
            <img src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315334/about/envgolupr4rgo3ngjeiq.png" 
            alt="Enablers Diaspora Grassroot Support Outreach" className="map-about-tab" />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="featureTab">
                            <Nav tabs className="nav justify-content-center">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggle('1'); }}
                                    >
                                        About EDGSO
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggle('2'); }}
                                    >
                                        Our mission
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggle('3'); }}
                                    >

                                        Our Vision
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className="pt-55">
                                <TabPane tabId="1">
                                    <div className="row">
                                        <div className="col-lg-10 mx-auto">
                                            <div className="aboutDetails text-center">
                                                {/* <p className="aboutDetailsText mb-20">Rorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna
                                                    aliqua. Ut enim ad minim veniam, quis exercitation ullamco laboris nisi ut aliquip ex ea
                                                    commodo.
                                                </p> */}
                                                <h5 className="aboutDetailsText text-5xl font-bold text-white mb-20">
                                                    We stand for Good
                                                    
                                                </h5>
                                            </div>
                                            <div className="aboutDetailsThumbs pt-100">
                                                <div className="flex flex-col md:flex-row w-full gap-3">
                                                            <img 
                                                            className='w-full md:w-[303px] h-[300px] object-cover rounded-tl-3xl rounded-bl-3xl'
                                                            src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315718/about/n6tlthg9gwdhsvp4vydx.png" 
                                                            alt="About EDGSO" />
                                                            <img
                                                            className='w-full md:w-[303px] h-[300px] object-cover'
                                                             src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315720/about/hflns56oicgdtgenkyif.png"
                                                            alt="About EDGSO" />
                                                            <img 
                                                            className='w-full md:w-[303px] h-[300px] object-cover rounded-tr-3xl rounded-br-3xl'
                                                            src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315714/about/z4calcqqfblzrumdmyea.png" alt="About EDGSO" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2">
                                    <div className="row">
                                        <div className="col-lg-10 mx-auto">
                                            <div className="aboutDetailsThumbs">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-lg-4">
                                                        <div className="aboutDetailsThumb">
                                                            <img src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315718/about/n6tlthg9gwdhsvp4vydx.png.png" 
                                                            alt="About Gainioz" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="aboutDetailsThumb aboutDetailsThumb--big">
                                                            <img src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315714/about/z4calcqqfblzrumdmyea.png"
                                                             alt="About Gainioz" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="aboutDetailsThumb">
                                                            <img src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315720/about/hflns56oicgdtgenkyif.png"
                                                            alt="About Gainioz" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aboutDetails text-center  pt-100">
                                                {/* <p className="aboutDetailsText mb-20">Rorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod
                                                    tempor incididunt ut labore et dolore magna
                                                    aliqua. Ut enim ad minim veniam, quis exercitation ullamco laboris nisi ut aliquip ex ea
                                                    commodo.
                                                </p> */}
                                                <h5 className="aboutDetailsText mb-20 text-dark">
                                                The mission of EDGSO is to provide free health interventions, health promotions,
                                                community giveaways and relief package to the less privileges in the world,
                                                in order to ameliorate sufferings. <br />
                                                “Happiness doesn't result from what we get, but from what we give." EDGSO <br />
                                                -Ben Carson.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="3">
                                <div className="row">
                                        <div className="col-lg-10 mx-auto">
                                            <div className="aboutDetails text-center">
                                                <h5 className="aboutDetailsText mb-20 text-dark">
                                                    Our Vision is to be able to alleviate suffering and poverty, improving the health of the less privileged.
                                                    To give hope to the hopeless through giveaways and free medical interventions, thereby promoting 
                                                    health and well being.
                                                </h5>
                                                
                                            </div>
                                            <div className="aboutDetailsThumbs pt-100">
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-lg-4">
                                                        <div className="aboutDetailsThumb">
                                                            <img src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315720/about/hflns56oicgdtgenkyif.png"
                                                            alt="EDGSO" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="aboutDetailsThumb aboutDetailsThumb--big">
                                                            <img src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315714/about/z4calcqqfblzrumdmyea.png"
                                                            alt="EDGSO" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="aboutDetailsThumb">
                                                            <img src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315718/about/n6tlthg9gwdhsvp4vydx.png" 
                                                            alt="EDGSO" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTab;
