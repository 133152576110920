import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom'

import Header2 from '../../components/Header2/Header2';
// import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import BlogSingle from '../../components/BlogDetails/BlogDetails';


const BlogDetails = (props) => {

    const { id } = useParams()

    // const BlogDetails = blogs.find(item => item.slug === slug)

    return (
        <Fragment>
            <Header2 hclass={'header--styleFour'} />
            <main className="main">
            {/* <PageTitle pageTitle={BlogDetails.title} pagesub={'Single Blog'} pageTop={'Blog'}/> */}
                <BlogSingle/>
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default BlogDetails;