import React from 'react'

import icon1 from '../../images/update/flag1.png'
import icon2 from '../../images/update/flag2.png'
import icon3 from '../../images/update/flag3.png'


const ContactSection = (props) => {
    return (
        <div>
            <div className="contact contact--layout1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-40">
                            <div className="contactBlock text-center">
                                <figure className="contactBlock__figure">
                                    <img
                                        className="contactBlock__figure__thumb"
                                        src={icon1}
                                        alt="EDGSO Contact"
                                        style={{ width: '40%', height: '40%', objectFit: 'cover' }} // Adjust width/height as needed
                                    />
                                </figure>
                                <div className="contactBlock__content">
                                    <h2 className="contactBlock__heading text-uppercase"> Nigeria </h2>
                                    <p className="contactBlock__text"> Ifesowapo Street, Oyo, Ibadan </p>
                                    <span className="contactBlock__email connect">enabler.nigeria@enablersworld.org</span>
                                    <span className="contactBlock__phone connect">+234 - 000 - 000</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-40">
                            <div className="contactBlock text-center">
                                <figure className="contactBlock__figure">
                                    <img
                                        className="contactBlock__figure__thumb"
                                        src={icon2}
                                        alt="EDGSO Contact"
                                        style={{ width: '40%', height: '40%', objectFit: 'cover' }} // Adjust width/height as needed
                                    />
                                </figure>
                                <div className="contactBlock__content">
                                    <h2 className="contactBlock__heading text-uppercase">South Africa</h2>
                                    <p className="contactBlock__text">2972 Westheimer Rd. Santa Ana, Illinois 85486</p>
                                    <span className="contactBlock__email connect">enablers.southafrica@enablersworld.org</span>
                                    <span className="contactBlock__phone connect">+1266 - 0000 - 0000</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-40">
                            <div className="contactBlock text-center">
                                <figure className="contactBlock__figure">
                                    <img
                                        className="contactBlock__figure__thumb"
                                        src={icon3}
                                        alt="EDGSO Contact"
                                        style={{ width: '40%', height: '40%', objectFit: 'cover' }} // Adjust width/height as needed
                                    />
                                </figure>
                                <div className="contactBlock__content">
                                    <h2 className="contactBlock__heading text-uppercase">United Kingdom</h2>
                                    <p className="contactBlock__text"> London </p>
                                    <span className="contactBlock__email connect">enablersinternational@enablersworld.org</span>
                                    <span className="contactBlock__phone connect">+474 - 0000 - 0000</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
        </div>
    )
}

export default ContactSection;