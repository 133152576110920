import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage/HomePage'
import AboutUsPage from '../AboutUsPage/AboutUsPage';
// import Donation from '../Donation/Donation';
import DonationStatic from '../Donation/DonationStatic';
// import DonationListing from '../DonationListing/DonationListing';
// import StorySinglePage from '../StorySinglePage/StorySinglePage';
import TeamPage from '../TeamPage/TeamPage';
// import TeamSinglePage from '../TeamSinglePage/TeamSinglePage';
import EventPage from '../EventPage/EventPage';
// import ShopPage from '../ShopPage/ShopPage';
// import ProductSinglePage from '../ProductSinglePage/ProductSinglePage';
// import CartPage from '../CartPage/CartPage';
import CheckoutPage from '../CheckoutPage/CheckoutPage';
// import ServicePage from '../ServicePage/ServicePage';
// import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
import BlogPage from '../BlogPage/BlogPage';
import BlogDetails from '../BlogDetails/BlogDetails';
import EventSinglePage from '../EventSinglePage/EventSinglePage';
import Terms from '../TermsPage/Terms';
import Privacy from '../PrivacyPage/Privacy';
import ContactPage from '../ContactPage/ContactPage';
import DonationComplete from '../Completions/DonationComplete';
import MessageSent from '../Completions/MessageSent';
import '../../index.css';


const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="about" element={<AboutUsPage />} />
          {/* <Route path="donation-listing" element={<DonationListing />} /> */}
          {/* <Route path="donation" element={<Donation />} /> */}
          <Route path="donation" element={<DonationStatic />} />
          {/* <Route path="donation-details/:slug" element={<DonationSinglePage />} /> */}
          {/* <Route path="story-details/:slug" element={<StorySinglePage />} /> */}
          <Route path="events" element={<EventPage />} />
          <Route path="event-single/:id" element={<EventSinglePage />} />
          <Route path="volunteers" element={<TeamPage />} />
          {/* <Route path="volunteers/:id" element={<TeamSinglePage />} /> */}
          {/* <Route path="products" element={<ShopPage />} />
          <Route path="product-single/:slug" element={<ProductSinglePage />} /> */}
          {/* <Route path="cart" element={<CartPage />} /> */}
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="blog" element={<BlogPage />} />
          {/* <Route path="service" element={<ServicePage />} />
          <Route path="service-single/:slug" element={<ServiceSinglePage />} /> */}
          <Route path="contact" element={<ContactPage />} />
          <Route path="donation-complete" element={<DonationComplete />} />  
          <Route path="message-sent" element={<MessageSent />} />  
          <Route path="blog-detail/:id" element={<BlogDetails />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
