import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DonationComplete = () => {
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    // Set up interval to countdown from 10 to 0
    const timer = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);

    // Navigate to home page after 5 seconds
    const redirect = setTimeout(() => {
      navigate('/');
    }, 10000);

    // Cleanup intervals when component unmounts
    return () => {
      clearInterval(timer);
      clearTimeout(redirect);
    };
  }, [navigate]);

  return (
    <div className="container text-center d-flex justify-content-center align-items-center vh-100">
      <div className="card p-4 shadow-lg">
        <div className="card-body">
          <h2 className="card-title mb-3">
            <i className="fas fa-heart text-danger"></i> &nbsp;
                Thank You for Your Donation! &nbsp;
            <i className="fas fa-heart text-danger"></i>
          </h2>
          <p className="card-text">
            We are incredibly grateful for your support. 
            Your generosity will make a positive difference!
          </p>
          <div className="spinner-border text-primary my-3" role="status">
            <span className="sr-only"> Loading... </span>
          </div>
          <p className="mt-3">Redirecting to the homepage <b> in { countdown} seconds...</b></p>
        </div>
      </div>
    </div>
  );
};

export default DonationComplete;
