import React from 'react'
import { Link } from 'react-router-dom'
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import '../../index.css';

const Terms = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="w-full flex flex-col p-3 md:p-5">
            <Header2 hclass={'header--styleFour'} />
            <PageTitle pageTitle={'Terms'} pagesub={'Terms'} />
             <div className="flex flex-col md:flex-row w-full gap-3">
                  
                <div className="flex flex-col  md:px-[1em]">
                                <div className="sectionTitle mb-20">
                                    <span className="sectionTitle__small">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Terms and Conditions
                                    </span>
                                    <h2 className="sectionTitle__big">
                                    Terms of Use

                                    Effective Date: 05 November 2024
                                    </h2>
                                </div>

                            <div className="aboutContent__text text-dark">
                                
                                <h6 className='mt-4 mb-2'> 1. Introduction </h6>

                                    Welcome to Enablersworld.org (the "Website"). 
                                    These Terms of Use govern your access to and use of the Website. 
                                    By accessing or using the Website, you agree to be bound by these Terms.

                                <h6 className='mt-4 mb-2'> 2. Intellectual Property Rights </h6>

                                    All content on the Website, including text, images, logos, and software (the "Content"), 
                                    is owned or licensed by Enablersworld.org. 
                                    You may not reproduce, distribute, or display the Content without prior written permission.

                                <h6 className='mt-4 mb-2'> 3. User Conduct </h6>

                                    You agree not to:

                                    - Use the Website for unlawful purposes
                                    - Post or transmit harmful, threatening, or obscene content
                                    - Interfere with the Website's operation
                                    - Infringe on others' rights

                                <h6 className='mt-4 mb-2'> 4. Disclaimer of Warranties </h6>

                                    The Website is provided "as is" without warranties of any kind.

                                <h6 className='mt-4 mb-2'> 5. Limitation of Liability </h6>

                                    Enablersworld.org shall not be liable for damages arising from Website use.

                                <h6 className='mt-4 mb-2'> 6. Governing Law </h6>

                                    These Terms shall be governed by [State/Country] laws.

                                <h6 className='mt-4 mb-2'> 7. Changes to Terms </h6>

                                    Enablersworld.org reserves the right to update these Terms.

                                <h6 className='mt-4 mb-2'> 8. Copyright Notice </h6>    

                                    Copyright 2024 Enablersworld.org. All rights reserved.

                                <h6 className='mt-4 mb-2'> 9. Privacy Policy </h6>

                                    <a href='/privacy'> Privacy Policy </a>

                                    *Acceptable Use Policy*

                                    <a href='/privacy'> Privacy Policy </a>


                                <h6 className='mt-4 mb-2'> 10. Contact Us </h6>

                                    For questions or concerns, visit <a href='/contact'> Contact us Page</a> <br />
                                    Or: <br />
                                    Contact via email: <a href='mailto:support@enablersworld.org'> </a>
                                    
                                <h6 className='mt-4 mb-2'> Disclaimer </h6>

                                    Enablersworld.org makes no representations or warranties regarding the accuracy 
                                    or completeness of the Content.
                            </div>
                </div>
            </div>
            <Footer />
            <Scrollbar />
        </section>
    )
}

export default Terms;
