import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
// import BlogSidebar from '../BlogSidebar';
import { DefaultTimeFormat } from '../../compFunc/dateTime';

const BlogList = (props) => {
    const [blogs, setBlogs] = useState([]);

    // Fetch data from the API
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(
                    'https://enablersworldwide.pythonanywhere.com/api/blog'
                );
                console.log(response.data);
                setBlogs(response.data.results);
            } catch (error) {
                console.error("Error fetching blog data:", error);
            }
        };
        fetchBlogs();
    }, [])

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <section className="donation pt-130 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mb-30">
                        <div className="innerWrapper">
                            <div className="row">

                                {blogs.map((blog) => (
                                    <div className="col-lg-12 mb-55" key={blog.id}>
                                        <div className="blogBlock blogBlock--style4 hoverStyle">
                                            <figure className="blogBlock__figure overflow-hidden">

                                                <Link
                                                    to={`/blog-detail/${blog.id}`}
                                                    state={{ blog }}>
                                                        <img src={`https://res.cloudinary.com/deofjoqll/${blog.cover_image}`} 
                                                        alt={blog.title} className="blogBlock__figure__image image-saturation" />
                                                </Link>

                                                {/* <Link onClick={ClickHandler} 
                                                    to={`/blog-detail/${formatTitleForUrl(blog.title)}`}
                                                    state={{ blog }}>
                                                        <img src={`https://res.cloudinary.com/deofjoqll/${blog.cover_image}`} 
                                                        alt={blog.title} className="blogBlock__figure__image image-saturation" />
                                                </Link> */}
                                                {/* to={`/blog-details/${blog.id}`} 
                                                    className="blogBlock__figure__link">
                                                    <img src={`https://res.cloudinary.com/deofjoqll/${blog.cover_image}`} 
                                                    alt={blog.title} className="blogBlock__figure__image image-saturation" />
                                                 */}
                                                
                                            </figure>
                                            <div className="blogBlock__content">
                                                <div className="blogBlock__button">
                                                    <Link onClick={ClickHandler} 
                                                    to={`/blog-detail/${blog.id}`}
                                                    className="btn btn--styleOne btn--icon btn--icon2 it-btn">
                                                        
                                                        <svg className="btn__icon" width="16" height="10" viewBox="0 0 16 10" fill="none" 
                                                        xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.9336 0.890625L10.6523 1.13672C10.5117 1.3125 10.5117 1.55859 10.6523 1.73438L13.6055 
                                                                4.65234H0.421875C0.175781 4.65234 0 4.86328 0 5.07422V5.42578C0 5.67188 0.175781 5.84766 0.421875 
                                                                5.84766H13.6055L10.6523 8.80078C10.5117 8.97656 10.5117 9.22266 10.6523 9.39844L10.9336 
                                                                9.64453C11.0742 9.82031 11.3555 9.82031 11.5312 9.64453L15.6094 5.56641C15.7852 5.39062 
                                                                15.7852 5.14453 15.6094 4.96875L11.5312 0.890625C11.3555 0.714844 11.0742 0.714844 10.9336 
                                                                0.890625Z" fill="#0B0B0B" />
                                                        </svg>
                                                        <span className="it-btn__inner">
                                                            <span className="it-btn__blobs">
                                                                <span className="it-btn__blob"></span>
                                                                <span className="it-btn__blob"></span>
                                                                <span className="it-btn__blob"></span>
                                                                <span className="it-btn__blob"></span>
                                                            </span>
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="blogBlock__header">
                                                    <div className="blogBlock__meta mb-15">
                                                        <ul className="blogBlock__meta__list">
                                                            <li>
                                                                <Link onClick={ClickHandler} to={`/blog-detail/${blog.id}`} 
                                                                className="blogBlock__metaUser">
                                                                    <img className="blogBlock__metaUser__thumb" src={blog.user.profile_image} 
                                                                    alt={blog.user.username} />
                                                                    <span className="blogBlock__metaUser__name">{blog.user.username}</span>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <h2 className="blogBlock__heading heading text-uppercase mb-20">
                                                        <Link onClick={ClickHandler} to={`/blog-detail/${blog.id}`} 
                                                        className="blogBlock__heading__link">
                                                            {blog.title}
                                                        </Link>
                                                    </h2>
                                                </div>
                                                <div className="blogBlock__body mb-25">
                                                    <p className="blogBlock__text text">
                                                        {blog.brief}
                                                    </p>
                                                </div>
                                                <div className="blogBlock__meta blogBlock__meta--style2">
                                                    <ul>
                                                        <li>
                                                            <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`} 
                                                            className="blogBlock__meta__text">
                                                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" 
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12.5 2H11V0.375C11 0.1875 10.8125 0 10.625 0H10.375C10.1562 0 
                                                                        10 0.1875 10 0.375V2H4V0.375C4 0.1875 3.8125 0 3.625 0H3.375C3.15625 0 3 0.1875 
                                                                        3 0.375V2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125
                                                                        16 14 15.3438 14 14.5V3.5C14 2.6875 13.3125 2 12.5 2ZM1.5 3H12.5C12.75 3 13 3.25 13
                                                                        3.5V5H1V3.5C1 3.25 1.21875 3 1.5 3ZM12.5 15H1.5C1.21875 15 1 14.7812 1 14.5V6H13V14.5C13
                                                                        14.7812 12.75 15 12.5 15ZM4.625 10C4.8125 10 5 9.84375 5 9.625V8.375C5 8.1875 4.8125 
                                                                        8 4.625 8H3.375C3.15625 8 3 8.1875 3 8.375V9.625C3 9.84375 3.15625 10 3.375 
                                                                        10H4.625ZM7.625 10C7.8125 10 8 9.84375 8 9.625V8.375C8 8.1875 7.8125 8 7.625 
                                                                        8H6.375C6.15625 8 6 8.1875 6 8.375V9.625C6 9.84375 6.15625 10 6.375 10H7.625Z" 
                                                                        fill="#666666" />
                                                                </svg>
                                                                <span>{DefaultTimeFormat(blog.date_posted)}</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* <BlogSidebar /> */}
                </div>
            </div>
        </section>
    );
};

export default BlogList;
