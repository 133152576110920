import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home'
    },
    {
        id: 2,
        title: 'About',
        link: '/about',
    },
    // {
    //     id: 3,
    //     title: 'Donations',
    //     link: '/donation-listing',
    //     submenu: [
    //         {
    //             id: 31,
    //             title: 'Donations Listing',
    //             link: '/donation-listing'
    //         }
    //     ]
    // },
    {
        id: 3,
        title: 'Blog',
        link: '/blog',
        // submenu: [
        //     {
        //         id: 41,
        //         title: 'Blog',
        //         link: '/blog'
        //     }
        // ]
    },
    {
        id: 4,
        title: 'Events',
        link: '/events',
    },
    {
        id: 5,
        title: 'Volunteers',
        link: '/volunteers',
    },
        // submenu: [
        //     {
        //         id: 51,
        //         title: 'Events',
        //         link: '/events'
        //     },
        //     {
        //         id: 51,
        //         title: 'Service',
        //         link: '/service'
        //     },
        //     {
        //         id: 53,
        //         title: 'Shop',
        //         link: '/products'
        //     },
        //     {
        //         id: 55,
        //         title: 'Carts',
        //         link: '/cart'
        //     },
        //     {
        //         id:56,
        //         title: 'Checkout',
        //         link: '/checkout'
        //     },
        //     {
        //         id: 345,
        //         title: 'Volunteers',
        //         link: '/volunteers'
        //     }
        // ]
    
    {
        id: 6,
        title: 'Contact',
        link: '/contact',
    }


]

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <ul className="main_menu_list clearfix">
            {menus.map((item, mn) => {
                return (
                    <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                        {item.submenu ?
                            <Fragment>
                                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                    <i className={item.id === openId ? 'ti-minus' : 'ti-plus'}></i>
                                </p>
                                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                    <List className="subMenu">
                                        <Fragment>
                                            {item.submenu.map((submenu, i) => {
                                                return (
                                                    <ListItem key={i}>
                                                        <Link onClick={ClickHandler} className="active"
                                                            to={submenu.link}>{submenu.title}</Link>
                                                    </ListItem>
                                                )
                                            })}
                                        </Fragment>
                                    </List>
                                </Collapse>
                            </Fragment>
                            : <Link className="active"
                                to={item.link}>{item.title}</Link>
                        }
                    </ListItem>
                )
            })}
        </ul>
    )
}

export default MobileMenu;
