import React, { Fragment } from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import AboutTab from './AboutTab';
import FunFactSection from '../../components/FunFact/FunFact';
import TeamSection from '../../components/TeamSection/TeamSection';

const AboutUsPage = (props) => {

    return (
        <Fragment>
            <Header2 hclass={'header--styleFour'} />
            <main className="main about-page">
                <PageTitle pageTitle={'About Us'} pagesub={'About'} />
                <section className="about pt-120 pb-105">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionTitle text-center mb-30">
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Enablers Diaspora Grassroot Support Outreach!
                                    </span>
                                    <h2 className="sectionTitle__big">We are always there others need help</h2>
                                </div>
                            </div>
                            <div className="col-lg-10 mx-auto">
                                <div className="aboutDetails text-center">
                                    <p className="aboutDetailsText mb-20">
                                    Enablers Diaspora Grassroots Support Outreach (hereinafter referred to as 'EDGSO') was established in September 2018 
                                    but registered on 24th August, 2020 with the Corporate Affairs Commission as a Non Governmental Organization, 
                                    non profit making, non political and non religious body founded by Chief Mrs. Ayoyinka Wilson a Registered Nurse
                                    and Midwife with over 30 years experience in both acute and primary health care Nursing in Nigeria and United Kingdom
                                    as well as a Philanthropist with a burning passion to help the less privileged and vulnerable people in the society
                                    and most especially within the grassroots community.
                                    EDGSO consists of members from a wide range of professional backgrounds both in Nigeria and in different parts of the world. 
                                    We are privileged to be supported by individuals from all walks of lifewith a deep passion to help the less privileged and 
                                    alleviate poverty in the communities.   
                                    </p>
                                    
                                    <p className="aboutDetailsText mb-20">
                                        
                                        We are a charitable organization dedicated to making a real difference in the lives of those who need 
                                        it most. We believe that everyone deserves access to basic necessities like healthcare, nutritious food, 
                                        and a chance at a healthier, more fulfilling life. Our mission is simple: to bring hope, 
                                        health, and happiness to the less privileged members of our community.

                                        We recognize the transformative power of compassion and generosity. When we come together to support 
                                        those in need, 
                                        we not only change individual lives but also create a ripple effect that benefits society as a whole. 
                                        Your support allows us to provide essential services that many people lack, such as free health 
                                        screenings, nutritious meals, and resources to improve their quality of life.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AboutTab />
                <FunFactSection />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 mx-auto">
                            <p className="aboutDetailsText mb-20 text-center">
                            In addition to healthcare, we work tirelessly to provide nutritious food 
                            to those who cannot afford it. Access to healthy food is a fundamental human right, 
                            yet many individuals and families struggle with food insecurity. 
                            Through our food distribution programs, we aim to ease this burden and ensure that 
                            everyone has the nourishment they need to thrive.

                            Our work is only possible with the generous support of people like you. 
                            Every donation, no matter the size, helps us continue our mission and expand our reach. 
                            </p>
                        </div>
                    </div>
                </div>
                <TeamSection />
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default AboutUsPage;
