import React, { Fragment } from 'react';
// import Header from '../../components/header/Header'
import Header2 from '../../components/Header2/Header2';
import Hero from '../../components/hero/hero';
import About from '../../components/about/about';
import Testimonial2 from '../../components/Testimonial2/Testimonial2';
// import StoriesSection from '../../components/StoriesSection/StoriesSection';
// import BlogSection from '../../components/BlogSection/BlogSection';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import '../../index.css';


const HomePage = () => {

    return (
        <Fragment>
            <div>
                <Header2 />
                <main className="page_content">
                    <Hero />
                    <About />
                    <Testimonial2 />
                    {/* <StoriesSection />
                    <BlogSection /> */}
                    <PartnerSection />
                </main>
                <Footer />
                <Scrollbar />
            </div>
        </Fragment>
    )
};
export default HomePage;