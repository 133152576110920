import React from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom';
import '../../index.css';

const Privacy = (props) => {
  const clickHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className="w-full flex flex-col p-3 md:p-5">
        <Header2 hclass={'header--styleFour'} />
        <PageTitle pageTitle={'Privacy Policy'} pagesub={'Privacy'} />
      <div className="flex flex-col md:flex-row w-full gap-3">
        <div className="flex flex-col md:px-[1em]">
          <div className="sectionTitle mb-20">
            <span className="sectionTitle__small">
              <i className="fa-solid fa-heart btn__icon"></i> Privacy Policy
            </span>
            <h6 className="sectionTitle__big">Last Updated: 05 November 2024</h6>
          </div>
          <div className="aboutContent__text text-dark">
            <p>Enablers World (the "Organization") is committed to protecting your privacy.</p>
            <h6 className='mt-4 mb-2'>Information We Collect</h6>
            <p>We collect only your email address when you contact us through our website.</p>
            <h6 className='mt-4 mb-2'>Use of Information</h6>
            <p>We use your email address solely to:</p>
            <ul>
              <li>Process your inquiry or request.</li>
              <li>Analyze website usage to improve our services.</li>
            </ul>
            <h6 className='mt-4 mb-2'>Data Security</h6>
            <p>We take reasonable measures to ensure your data is secure and protected against unauthorized access, 
                disclosure, alteration, or destruction.</p>
            <h6 className='mt-4 mb-2'>Data Sharing</h6>
            <p>We do not share your data with any third-party organizations.</p>
            <h6 className='mt-4 mb-2'>Retention</h6>
            <p>We retain your data only for as long as necessary to fulfill the purposes outlined above.</p>
            <h6 className='mt-4 mb-2'>Your Rights</h6>
            <p>You have the right to:</p>
            <ul>
              <li>Access your data.</li>
              <li>Correct or update your data.</li>
              <li>Request deletion of your data.</li>
            </ul>
            <h6 className='mt-4 mb-2'>Contact Us</h6>
            <p>For questions or concerns, please email: <a href="mailto:support@enablersworld.org">
                support@enablersworld.org</a>.</p>
            <h6 className='mt-4 mb-2'>Governing Law</h6>
            <p>This Privacy Policy shall be governed by Nigeria, United Kingdom and South African laws.</p>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </section>
  );
};

export default Privacy;
