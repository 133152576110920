import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import { useNavigate } from 'react-router-dom';

// import Countries from '../../compFunc/countries';

const ContactForm = () => {
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();

  const [forms, setForms] = useState({
    full_name: '',
    email: '',
    country: '',
    enquiry: '',
    body: ''
  });

  const [validator] = useState(
    new SimpleReactValidator({
      className: 'errorMessage'
    })
  );

  useEffect(() => {
    // Fetch country list from the backend on component mount
    axios
      .get('https://enablersworldwide.pythonanywhere.com/users/countries/')
      .then((response) => {
        // Assume each country in response data is in the format { code: 'US', name: 'United States' }
        const countryList = response.data.map((country) => ({
          code: country.code,
          name: country.name
        }));
        setCountries(countryList);
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
  }, []);

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      // Simulate a 1.5 second delay before navigating to the new page
    setTimeout(() => {
      // setLoading(false); // Stop loading after 1.5 seconds
      navigate("/message-sent");
    }, 1500);
  
      axios
        .post('https://enablersworldwide.pythonanywhere.com/users/contact/', forms)
        .then((response) => {
          // handle success (show success message, clear form, etc.)
          setForms({
            full_name: '',
            email: '',
            country: '',
            enquiry: '',
            body: ''
          });
        })
        .catch((error) => {
          // handle error (show error message)
          console.error('Error submitting form:', error);
        });
    } else {
      validator.showMessages();
    }
  };

  return (
    <form
      id="contact-form"
      className="it-contact-form commentsPost commentsPost--style2 pt-45 pb-25"
      onSubmit={submitHandler}
    >
      <div className="row g-4">
        <div className="col-md-6">
          <div className="commentsPost__input">
            <input
              value={forms.full_name}
              type="text"
              name="full_name"
              className="form-control"
              onBlur={changeHandler}
              onChange={changeHandler}
              placeholder="Enter your name*"
            />
            {validator.message('full_name', forms.full_name, 'required|alpha_space')}
          </div>
        </div>

        <div className="col-md-6">
          <div className="commentsPost__input">
            <input
              value={forms.email}
              type="email"
              name="email"
              className="form-control"
              onBlur={changeHandler}
              onChange={changeHandler}
              placeholder="Enter your email*"
            />
            {validator.message('email', forms.email, 'required|email')}
          </div>
        </div>

        <div className="col-md-6">
        <div className="commentsPost__input">
          <select
            name="country"
            value={forms.country}
            onChange={changeHandler}
            className="form-control"
          >
            <option value="">Select Country*</option>
            {countries.map((country, index) => (
              <option key={index} value={country.code}>
              {country.name}
              </option>
            ))}
          </select>
          {validator.message('country', forms.country, 'required')}
        </div>
      </div>

        <div className="col-md-6">
          <div className="commentsPost__input">
            <select
              value={forms.enquiry}
              name="enquiry"
              className="form-control"
              onBlur={changeHandler}
              onChange={changeHandler}
            >
              <option value="">Select Enquiry Type*</option>
              <option value="Event">Event</option>
              <option value="Donation">Donation</option>
              <option value="Partnership">Partnership</option>
              <option value="Volunteer">Volunteer</option>
              <option value="Other">Other</option>
            </select>
            {validator.message('enquiry', forms.enquiry, 'required|enquiry')}
          </div>
        </div>

        <div className="col-12">
          <div className="commentsPost__input">
            <textarea
              onBlur={changeHandler}
              onChange={changeHandler}
              value={forms.body}
              name="body"
              className="form-control"
              placeholder="Enter your message*"
            ></textarea>
            {validator.message('body', forms.body, 'required')}
          </div>
        </div>

        <div className="col-12">
          <div className="commentsPost__button text-center">
            <button type="submit" className="btn btn--styleOne btn--primary it-btn">
              <span className="btn__text">Send message</span>
              <i className="fa-solid fa-heart btn__icon"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="form-response"></div>
    </form>
  );
};

export default ContactForm;
