import React, { Fragment } from 'react';
import Header2 from '../../components/Header2/Header2';
import PageTitle from '../../components/pagetitle/PageTitle';
import ContactForm from '../../components/ContactForm';
import ContactSection from '../../components/ContactSection';
import Map from '../../components/map/Map';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';

const ContactPage = (props) => {

    return (
        <Fragment>
            <Header2 hclass={'header--styleFour'}/>
            <main className="main">
                <PageTitle pageTitle={'Contact Us'} pagesub={'Contact'}/>
                <ContactSection />
                <ContactForm />
                <Map />
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default ContactPage;
