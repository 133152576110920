import React from 'react'
import { Link } from 'react-router-dom'
import FeaturesSection from '../FeaturesSection/FeaturesSection';
import '../../index.css';


const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="w-full flex flex-col p-3 md:p-5">
             <div className="flex flex-col md:flex-row w-full gap-3">
                    <img 
                     
                     src="https://res.cloudinary.com/deofjoqll/image/upload/v1730315696/about/kk5cramxaasvnwa80sdp.png"
                     alt="Enablers Diaspora Grassroot Support Outreach" 
                     className="w-full  object-cover md:w-[50%] " />
                  
                <div className="flex flex-col  md:px-[1em]">
                                <div className="sectionTitle mb-20">
                                    <span className="sectionTitle__small">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        About Enablers Diaspora Grassroot Support Outreach
                                    </span>
                                    <h2 className="sectionTitle__big">
                                        What we stand for.
                                    </h2>
                                </div>
                                <p className="aboutContent__text text-dark">
                                    
                                    Enablers Diaspora Grassroots Support Outreach (hereinafter referred to as 'EDGSO') was established in September 2018 
                                    but registered on 24th August, 2020 with the Corporate Affairs Commission as a Non Governmental Organization, 
                                    non profit making, non political and non religious body founded by Chief Mrs. Ayoyinka Wilson a Registered Nurse
                                    and Midwife with over 30 years experience in both acute and primary health care Nursing in Nigeria and United Kingdom
                                    as well as a Philanthropist with a burning passion to help the less privileged and vulnerable people in the society
                                    and most especially within the grassroots community.
                                    EDGSO consists of members from a wide range of professional backgrounds both in Nigeria and in different parts of the world. 
                                    We are privileged to be supported by individuals from all walks of lifewith a deep passion to help the less privileged and 
                                    alleviate poverty in the communities.
                                    

                                </p>
                                <span className="uppercase text-2xl font-bold mb-1"> Join our Action and everyone can help </span>
                                <Link onClick={ClickHandler} className="btn btn--styleOne btn--secondary it-btn" to="/donation">
                                    <span className="btn__text">Donate now</span>
                                    <i className="fa-solid fa-heart btn__icon"></i>
                                    <span className="it-btn__inner">
                                        <span className="it-btn__blobs">
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                            <span className="it-btn__blob"></span>
                                        </span>
                                    </span>
                                    <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                        <defs>
                                            <filter>
                                                <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                </feGaussianBlur>
                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                </feColorMatrix>
                                                <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                            </filter>
                                        </defs>
                                    </svg>
                                </Link>
                </div>
            </div>
            <FeaturesSection/>

        </section>
    )
}

export default About;
