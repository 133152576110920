import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PageTitle from '../pagetitle/PageTitle';
import BlogSidebar from '../BlogSidebar';

const BlogSingle = () => {
    const [post, setPost] = useState({});

    const { id } = useParams();

    console.log(id)
    const fetchPost = async () => {
        try {
            const response = await axios.get(`https://enablersworldwide.pythonanywhere.com/api/blog/${id}`);
            console.log(response);
            setPost(response.data);
        } catch (error) {
            console.error("Error fetching blog data:", error);
        }
    };

    useEffect(() => {
        fetchPost();
    }, [id])

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    // const sanitizedContent = DOMPurify.sanitize();

    // console.log("Title is:" + title)

    if (!post) {
        return <p>Blog not found.</p>;
    }

    return (
        <>
        <PageTitle pageTitle={post.title} pagesub={post.title} pageTop={'Blog'}/>
        <section className="stories pt-130 pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mb-30">
                        <div className="innerWrapper">
                            <div className="donationDetails storiesDetails">
                                <div className="donationDetails__header mb-45">
                                    <figure className="thumb mb-45">
                                        <img src={`https://res.cloudinary.com/deofjoqll/${post.cover_image}`}
                                         alt={post.title} 
                                        className="video-aspect" />
                                    </figure>
                                </div>
                                <h4 className="uppercase mb-25 underline text-center">{post.title}</h4>
                                <div 
                                    className="donationDetails__text storiesDetails__text flex flex-col gap-[2em] mb-30"
                                    dangerouslySetInnerHTML={{ __html: post.body }}>
                                    
                                </div>
                                {/* Add other BlogDetails properties here */}
                                
                                {/* Social Sharing and Tags Section */}
                                <div className="blogDetails-socialTags mb-35">
                                    {/* Tag and Share Section */}
                                </div>
                                
                                {/* Next and Previous Blog Section */}
                                <div className="blogDetails-nextPrevious">
                                    {/* Navigation Section */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar />
                </div>
            </div>
        </section>
        </>
    );
};

export default BlogSingle;
